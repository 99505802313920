import React from "react";
import AddButton from "../AddButton";
import LoadingIndicator from "../LoadingIndicator";
import Table from "../Table";
import TableBody from "../Table/TableBody";
import TableHead from "../Table/TableHead";
import $ from 'jquery'
import Swal from "sweetalert2";
import ModalAddTratamientoExpediente from "../ModalAddTratamientoExpediente";
import addProcesoExpediente from "../../services/expediente/addProcesoExpediente";
import getProcesosExpediente from "../../services/expediente/getProcesosExpediente";
import CardCostoTratamientosExpediente from "../CardCostoTratamientosExpediente";

export default function ExpedienteTratamientos(props){

    let { modulosPermisos } = props

    const [elements, setElements] = React.useState([]);
    const [totalPresupuesto, setTotalPresupuesto] = React.useState(0);
    const [totalPagado, setTotalPagado] = React.useState(0);
    const [presupuestoUnidades, setPresupuestoUnidades] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const rows = [
        { id: 1, name: "Nombre" },
        { id: 2, name: "Dientes" },
        { id: 3, name: "Fecha comienzo" },
        { id: 4, name: "Precio total" }
    ];

    function getProcesosExpedienteService(){
        setLoading(true)
        let data = { folio: props.folio }
        getProcesosExpediente({values: data}).then(res => {
            console.log(res)
            let _res = JSON.parse(res);
            if(_res.response === "success"){
                setElements(_res.data);
                setTotalPresupuesto(_res.total_presupuesto)
                setTotalPagado(_res.total_pagado)
                setPresupuestoUnidades(_res.presupuesto_unidades)
            }
            setLoading(false)
        }).catch((error) => {
            // console.log(error)
            // console.log("ha petado")
        })
    }
    const onSubmitModal = (e)=>{
        e.preventDefault()

        // Desactivamos el botón submit del formulario
        let btnSubmit = document.getElementById('btnModalAddTratamientoExpedienteSubmit');
        btnSubmit.disabled = true;
        btnSubmit.innerHTML = 'Asignando...';

        const formData = new FormData(e.target);
        formData.append("folio",props.folio);
        let objectValues = Object.fromEntries(formData);
        let dientes = $("#dientes_involucrados").val();
        objectValues.dientes_involucrados = dientes
        // console.log({objectValues})
        addProcesoExpediente({values:objectValues})
            .then(res=>{
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    Swal.fire({
                        title:'Asignado correctamente',
                        timer:1200,
                        showConfirmButton:false,
                        icon:'success'
                    })
                    $("#modalAddTratamientoExpediente").modal("hide")
                    getProcesosExpedienteService()
                }
                // Habilitamos el botón submit del formulario
                btnSubmit.disabled = false;
                btnSubmit.innerHTML = 'Asignar';
            })
    }

    function reloadTratamientos(){
        getProcesosExpedienteService()
    }

    React.useEffect(getProcesosExpedienteService,[]);

    return(
        <div>

            {
                presupuestoUnidades.length !== 0 &&
                    presupuestoUnidades.map(presupuesto => {
                        return (
                            <>
                                <h3>
                                    {presupuesto.nombre_unidad}
                                </h3>
                                <CardCostoTratamientosExpediente 
                                    total_presupuesto={presupuesto.total_presupuesto}
                                    total_ha_pagado={presupuesto.total_presupuesto_pagado}
                                    total_debe={presupuesto.total_presupuesto - presupuesto.total_presupuesto_pagado}
                                />
                            </>
                        )
                    })
            }

            <ModalAddTratamientoExpediente
                onSubmitModal={onSubmitModal}
            />

            <div className='d-flex align-items-center mb-4'>
                <h4>
                    Tratamientos del paciente
                </h4>

                {
                    modulosPermisos.expedientesTratamientos.escribir === '1' &&
                        <AddButton text="Agregar tratamiento" onClick={ async () => {
                            $('#modalAddTratamientoExpediente').modal('show')
                        } } />
                }
            </div>

            <LoadingIndicator show={loading} />

            {
                ( Object.keys(elements).length !== 0 && !loading ) &&

                    <Table>
                        <TableHead rows={rows} />

                        <TableBody elements={elements} typeElement="proceso_expediente" reload={reloadTratamientos} permisosUsuario={modulosPermisos} />
                    </Table>
            }

        </div>
    )
}