import React from 'react';

export default function TableElementHistorialOrdenTrabajo({ element }) {

    let {
        id_historial_orden_trabajo,
        descripcion,
        entregado,
        etapa,
        es_terminado,
        fecha_alta,
        fecha_entrega,
        fecha_que_entrego,
        fecha_recepcion,
        pago
    } = element;

    return (
        <tr key={id_historial_orden_trabajo}>
            <th className='table-max-20'>
                <div className="table__name">
                    {/* <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                <button 
                                    className="dropdown-item"
                                >
                                    Ver detalles
                                </button>
                            }
                            {
                                <button
                                    className="dropdown-item bg-success text-white"
                                >
                                    He recibido el trabajo
                                </button>
                            }
                            {
                                <button
                                    className="dropdown-item bg-danger text-white"
                                >
                                    Enviar al laboratorio de nuevo
                                </button>
                            }
                        </div>
                    </div> */}

                    <div>
                        
                        {
                            fecha_recepcion !== null &&
                            <small className='my-2'>
                                Recolectado el: 
                                <br/>
                                <span className='font-weight-bold'>
                                    {fecha_recepcion}
                                </span>
                            </small>
                        }
                        {
                            fecha_entrega !== null && fecha_que_entrego === null &&
                            <>
                                <br />
                                <small>
                                    Próxima entrega:
                                    <br/>
                                    <span className='font-weight-bold'>
                                        {fecha_entrega}
                                    </span>
                                </small>
                            </>
                        }
                        {
                            fecha_que_entrego !== null &&
                            <>
                                <br />
                                <small>
                                    Lo entregó el:
                                    <br/>
                                    <span className='font-weight-bold'>
                                        {fecha_que_entrego}
                                    </span>
                                </small>
                            </>
                        }
                    </div>
                </div>
            </th>

            <td className='table-max-20'>
                {
                    es_terminado === '1' &&
                    <div className="table__estatus">
                        <span>
                            Para finalizar
                        </span>
                    </div>
                }
                <p className='my-2'>{etapa}</p>
                <small className="font-weight-bold">
                    Pago: {parseFloat(pago).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </small>
            </td>

            <td className='table-null-no-wrap'>
                <small>
                    {descripcion}
                </small>
            </td>

        </tr>
    )

}