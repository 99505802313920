import React from 'react'
import AlertWarningSearch from '../AlertWarningSearch'
import LoadingIndicator from '../LoadingIndicator'
import Table from '../Table'
import TableBody from '../Table/TableBody'
import TableHead from '../Table/TableHead'
import $ from 'jquery'
import CardIngresosUnidad from '../CardIngresosUnidad'
// import getCitasEmpleado from '../../services/empleado/getCitasEmpleado'
// import getCitasTotalesEmpleado from '../../services/empleado/getCitasTotalesEmpleado'
import FilterButton from '../FilterButton'
import Icon from '../Icon'
import useCitas from '../../hooks/useCitas'

export default function TrabajadorCitas(props) {
    let { modulosPermisos, id_trabajador } = props

    // VARIABLES PARA LAS CITAS
    const {
        citas,
        loading,
        trabajadorCitas,
        fechaInicio,
        fechaFinal,
        setFechaInicio,
        setFechaFinal,
        getCitasByEmpleadoService,
    } = useCitas()

    const [idUnidadCita, setIdUnidadCita] = React.useState(null)
    const [showDeleteFilters, setShowDeleteFilters] = React.useState(false)
    const [loadingFilters, setLoadingFilters] = React.useState(false)

    const rows = [
        { id: 1, name: "Fecha" },
        { id: 1, name: "Paciente" },
        // { id: 3, name: "Descripción" }
    ];

    const reloadCitas = () => {
        let data = {
            id_trabajador: id_trabajador,
        }
        if (idUnidadCita !== null) {
            data.id_unidad = idUnidadCita
        }

        getCitasByEmpleadoService(data)
        // getCitasTotalesTrabajadorService()
    }

    function onSubmitFechas(e) {
        e.preventDefault()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        setFechaInicio(objectValues.fecha_inicial)
        setFechaFinal(objectValues.fecha_final)
    }

    const onSubmitFilter = (tipoFiltro, e) => {
        e.preventDefault()
        setShowDeleteFilters(true)
        let values = new FormData(e.target)
        let object = Object.fromEntries(values)

        setIdUnidadCita(object.id_unidad)
    }

    const onClickDeleteFilters = () => {
        setIdUnidadCita(null)
        setShowDeleteFilters(false)
        // Ocultamos y mostramos de nuevo los filtros para limpiar los valores seleccionados
        setLoadingFilters(true)
        setTimeout(() => {
            setLoadingFilters(false)
        }, 50)
    }

    React.useEffect(reloadCitas, [fechaInicio, fechaFinal, idUnidadCita])
    // React.useEffect(getCitasTotalesTrabajadorService, [])
    React.useEffect(() => $('#fecha_inicial').val(fechaInicio), [])

    return (
        <>
            {/* <CardIngresosUnidad
                total_ingresos_efectivo={trabajadorCitas.total_ingresos_efectivo}
                total_ingresos_banco={trabajadorCitas.total_ingresos_banco}
                total_ingresos={trabajadorCitas.total_ingresos}
            /> */}

            <div className="mb-4">
                <h4 className="">Citas realiadas a pacientes</h4>

                <form onSubmit={onSubmitFechas} className={`d-flex justify-content-between align-items-center mt-3`}>
                    <input
                        id="fecha_inicial"
                        name="fecha_inicial"
                        className="input noMargin"
                        type="date"
                        defaultValue={fechaInicio}
                        required
                    />
                    <p className="mx-2">y</p>
                    <input
                        id="fecha_final"
                        name="fecha_final"
                        className="input noMargin"
                        type="date"
                        defaultValue={fechaFinal}
                        required
                    />
                    <button className="btn btn-gris">
                        <i className="fa-solid fa-search"></i>
                    </button>
                </form>
            </div>

            <hr className="my-2" />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {!loadingFilters && (
                        <>
                            <FilterButton text="Unidad" type="id_unidad" onSubmit={onSubmitFilter} />
                        </>
                    )}
                </div>

                <button
                    className={`btn btn-sm btn-light text-muted ${!showDeleteFilters && 'hidden'}`}
                    onClick={() => onClickDeleteFilters()}
                >
                    <Icon icon="fa-solid fa-x" /> Eliminar
                </button>
            </div>

            <LoadingIndicator show={loading} />

            {citas.length !== 0 && !loading && (
                <Table>
                    <TableHead rows={rows} />

                    <TableBody
                        reload={reloadCitas}
                        elements={citas}
                        typeElement="cita_trabajador"
                        permisosUsuario={modulosPermisos}
                    />
                </Table>
            )}

            {citas.length === 0 && !loading && <AlertWarningSearch />}
        </>
    )
}
