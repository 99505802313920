import React from "react"
import getTipoUsers from "../../services/user/getTipoUsers"
import $ from 'jquery'
import getTodosLosPermisos from "../../services/user/getTodosLosPermisos"
import Table from "../Table";
import TableHead from "../Table/TableHead";
import TableBody from "../Table/TableBody";

export default function ModalViewOrdenTrabajo({ onSubmitModal, elementToEdit }) {

    if (elementToEdit === null) {
        return null;
    }

    const {
        apellido_materno,
        apellido_materno_empleado,
        apellido_paterno,
        apellido_paterno_empleado,
        cantidad_a_cuenta,
        colorimetria,
        costo_total,
        entregado_ultima_orden,
        etapa,
        fecha_alta,
        fecha_entrega,
        fecha_que_entrego,
        fecha_recepcion,
        folio,
        historial_ordenes_trabajo,
        id_empleado,
        id_estado_orden_trabajo,
        id_orden_trabajo,
        id_trabajo_laboratorio,
        id_unidad,
        nombre,
        nombre_empleado,
        nombre_estado_orden_trabajo,
        nombre_laboratorio,
        nombre_trabajo_laboratorio,
        nombre_unidad,
        numero_orden,
        organos_dentales,
        terminado
    } = elementToEdit;
    
    let parse_organos_dentales = JSON.parse(organos_dentales);

    return (
        <div className="modal" id="modalViewOrdenTrabajo" tabIndex="-1" role="dialog" aria-labelledby="modalViewOrdenTrabajoLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={onSubmitModal} className="modal-content" id="formViewOrdenTrabajo">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title" id="modalViewOrdenTrabajoLabel">
                                Trabajo dental del paciente: {`${nombre} ${apellido_paterno} ${apellido_materno}`}
                            </h5>
                            <small className="text-muted"> Folio del expediente: {folio} - Fecha de alta: {fecha_alta}</small>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4 className="mb-2">Detalles</h4>

                        <div className="table__estatus">
                            <span className={ terminado === '1' ? '' : 'table__estatus-inactive' }>
                                { terminado === '1' ? 'Trabajo terminado' : 'Trabajo no terminado' }
                            </span>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Laboratorio:</p>
                                <p>{nombre_laboratorio}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Estado del trabajo:</p>
                                <p>{nombre_estado_orden_trabajo}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Número de orden: </p>
                                <p>{`${numero_orden}`}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Trabajo dental: </p>
                                <p>{`${nombre_trabajo_laboratorio}`}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Quién solicitó el trabajo: </p>
                                <p>{`${nombre_empleado} ${apellido_paterno_empleado} ${apellido_materno_empleado}`}</p>
                                <small className="text-muted">
                                    Unidad: {`${nombre_unidad}`}
                                </small>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Costo total: </p>
                                <p>{`${parseFloat(costo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</p>
                            </div>
                        </div>

                        <hr className="my-2" />

                        <div className="row mt-3">
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Fecha cuando el laboratorio se llevó el trabajo:</p>
                                <p>{fecha_recepcion}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Ya entregó la última orden?</p>
                                <p>{entregado_ultima_orden === "0" ? 'No' : 'Si'}</p>
                            </div>
                            {
                                fecha_que_entrego !== null &&
                                    <div className="col-lg-4 col-md-6 my-2">
                                        <p className="font-weight-bold">Fecha cuando entregó:</p>
                                        <p>{fecha_que_entrego}</p>
                                    </div>
                            }
                            {
                                fecha_que_entrego === null &&
                                    <div className="col-lg-4 col-md-6 my-2">
                                        <p className="font-weight-bold">Fecha de próxima entrega:</p>
                                        <p>{fecha_entrega}</p>
                                    </div>
                            }
                            <div className="col-lg-4 col-md-6 my-2">
                                <p className="font-weight-bold">Colorimetría: </p>
                                <p>{`${colorimetria}`}</p>
                            </div>
                            <div className="col-lg-8 col-md-6 my-2">
                                <p className="font-weight-bold">Organos dentales: </p>
                                {
                                    parse_organos_dentales !== null && 
                                    parse_organos_dentales.map(
                                        (diente, index) => {
                                            if(parse_organos_dentales.length === 1 || parse_organos_dentales.length === index + 1){
                                                return <p key={diente}>{diente}</p>
                                            }

                                            return <p>{`${diente}, `}</p>
                                        }
                                    )
                                }
                                {
                                    parse_organos_dentales === null && <p>No aplica</p>
                                }
                            </div>
                        </div>

                        {/* <p>Terminado: {terminado === "0" ? 'No' : 'Si'}</p>
                        <p>Cantidad a cuenta: {cantidad_a_cuenta}</p> */}

                        <hr className="mt-2 mb-4"/>

                        <h4>Historial</h4>

                        <Table>
                            <TableHead rows={[
                                { id: 1, name: "Fechas" },
                                { id: 2, name: "Etapa" },
                                { id: 3, name: "Descripción" }
                            ]} />

                            <TableBody 
                                elements={historial_ordenes_trabajo} 
                                typeElement="historial_orden_trabajo"
                            />
                        </Table>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Salir</button>
                        {/* <button type="submit" className="btn btn-primary">
                            {
                                elementToEdit !== null ? "Actualizar" : "Agregar"
                            }
                        </button> */}
                    </div>
                </form>
            </div>
        </div>
    )
}