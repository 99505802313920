import React from 'react'
import './App.css'

// Utilidades y librerias

// Vistas de la plataforma
import HomePage from './views/HomePage'
import Login from './views/Login'

// Componentes de la plataforma
import LoadingIndicator from './components/LoadingIndicator'
import getPermisos from './services/user/getPermisos'

function App() {

    // Estados globales de la app
    const [idUsuario, setIdUsuario] = React.useState(null)
    const [login, setLogin] = React.useState(false)
    const [permisos, setPermisos] = React.useState(false)
    const [loadingInitialData, setLoadingInitialData] = React.useState(true);

    React.useEffect(() => {
        // Si existe user en localStorage, significa que ya tiene una sesion iniciada.
        let id = localStorage.getItem('idUsuario')
        if(id){
            getPermisosService(id)
            setIdUsuario(id)
            setLogin(true)
        } else {
            localStorage.clear();
            setLoadingInitialData(false)
        }

    },[]);

    const getPermisosService = (id_usuario) =>{
        getPermisos({values:{id_usuario}})
            .then(res => {
                // console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === 'success'){
                    setPermisos(_res.data)
                } else {
                    setPermisos([])
                }
                // Quitamos el LoadingIndicator
                setLoadingInitialData(false)
            })
            .catch(err => {
                console.log(err); 
                setLoadingInitialData(false)
                localStorage.clear();
            })
    }

    return (
    <>  
        {/* Mostramos un loading en lo que carga toda la informacion */}
        <LoadingIndicator show={loadingInitialData}/>

        {
            // Si existe un usuario y ya se cargo toda la informacion, muestra el HomePage
            (login && !loadingInitialData) &&
                <HomePage 
                    setLogin={setLogin}
                    
                    idUsuario={idUsuario}
                    setIdUsuario={setIdUsuario} 

                    permisos={permisos}
                    setPermisos={setPermisos}
                />
        }
        {
            // Si no existe un usuario y ya se cargo toda la informacion, muestra el Login
            (!login && !loadingInitialData) &&
                <Login 
                    setIdUsuario={setIdUsuario}
                    setLogin={setLogin}
                    setPermisos={setPermisos}
                />
        }

    </>
  );
}

export default App;
