import React from 'react';
import Swal from 'sweetalert2';
import $ from 'jquery';
import isTrabajoRecibido from '../../services/orden_trabajo/isTrabajoRecibido';

export default function TableElementOrdenTrabajo({ element, permisosUsuario, reload, onClickVerDetalles, setElementToEdit }) {

    let {
        apellido_materno,
        apellido_paterno,
        cantidad_a_cuenta,
        colorimetria,
        costo_total,
        etapa,
        entregado_ultima_orden,
        fecha_alta,
        fecha_entrega,
        fecha_recepcion,
        fecha_que_entrego,
        folio,
        id_empleado,
        id_estado_orden_trabajo,
        id_orden_trabajo,
        id_trabajo_laboratorio,
        id_unidad,
        nombre,
        nombre_estado_orden_trabajo,
        nombre_laboratorio,
        nombre_trabajo_laboratorio,
        numero_orden,
        organos_dentales,
        terminado,
        nombre_empleado,
        apellido_paterno_empleado,
        apellido_materno_empleado,
        nombre_unidad,
        total_pagado
    } = element;

    const getClassBackgroundByEstatus = (estatus) => {
        switch (estatus) {
            case '1':
                return 'table__bg-azul';
            case '2':
                return 'table__bg-naranja';
            case '3':
                return 'table__bg-rojo';
            case '4':
                return 'table__bg-verde';
            default:
                return '';
        }
    }

    const onClickHeRecibidoTrabajo = () => {
        Swal.fire({
            title: "Asegúrate de haber recibido el trabajo",
            text: "Esto avisará al doctor para que pueda recogerlo a tu oficina",
            showCancelButton: true,
            confirmButtonText: "Si, he recibido el trabajo",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Procesando',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    allowOutsideClick: false
                })

                let values = new FormData()
                values.append('id_orden_trabajo', id_orden_trabajo)
                let objectValues = Object.fromEntries(values);
                console.log({ objectValues })
                isTrabajoRecibido({ values: objectValues })
                    .then(response => {
                        console.log(response)
                        let _respuesta = JSON.parse(response);
                        if (_respuesta.response === "success") {
                            reload()
                            Swal.fire(
                                'Estatus actualizado',
                                '',
                                'success'
                            )
                        }

                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire(
                            'Error',
                            'No se pudo actualizar el estatus',
                            'error'
                        )
                    })
            }
        })
    }

    const onClickEnviarAlLaboratorioDeNuevo = () => {
        if (id_estado_orden_trabajo !== '3') {
            Swal.fire({
                title: "No se puede enviar al laboratorio",
                text: "Primero el doctor debe confirmar en el sistema que ha hecho una cita con el paciente para probar el trabajo.",
                icon: "info",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
            })
            return;
        }

        setElementToEdit(element)
        $("#modalEnviarTrabajoLaboratorio").modal('show')
    }

    return (
        <tr key={id_orden_trabajo} className={`${getClassBackgroundByEstatus(id_estado_orden_trabajo)}`}>
            <th className='table-max-30'>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <Link to={`/inventario/${id_unidad}/${id_orden_trabajo}`} className="dropdown-item">
                                Ver
                            </Link> */}
                            {
                                <button 
                                    className="dropdown-item"
                                    onClick={() => onClickVerDetalles(id_orden_trabajo)}
                                >
                                    Ver detalles
                                </button>
                            }
                            {
                                permisosUsuario.laboratorios.editar === '1' &&
                                entregado_ultima_orden === '0' &&
                                <button
                                    className="dropdown-item bg-success text-white"
                                    onClick={onClickHeRecibidoTrabajo}
                                >
                                    He recibido el trabajo
                                </button>
                            }
                            {
                                permisosUsuario.laboratorios.escribir === '1' &&
                                entregado_ultima_orden === '1' &&
                                <button
                                    className="dropdown-item bg-danger text-white"
                                    onClick={onClickEnviarAlLaboratorioDeNuevo}
                                >
                                    Enviar al laboratorio de nuevo
                                </button>
                            }
                            {/* {
                                (permisosUsuario.inventarioMateriales.escribir === '1') &&
                                    <a href className="dropdown-item text-black" onClick={() => {
                                        if(total_cantidad_disponible >= 1){
                                            setElementToEdit(element)
                                            $("#modalAddSalidaMaterial").modal('show')
                                            return;
                                        }

                                        Swal.fire({
                                            title: "No hay materiales",
                                            text: 'Primero agrega más al inventario pulsando el botón de "+"',
                                            icon: "info"
                                        })
                                    }}>
                                        Marcar una salida de material
                                    </a>
                            } */}
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <small className="text-muted">Orden: {numero_orden}</small>
                        <p>{nombre} {apellido_paterno} {apellido_materno}</p>
                        <small className="text-muted">Solicitado por: <br/>{`${nombre_empleado} ${apellido_materno_empleado}`}</small>
                    </div>
                </div>
            </th>

            <td>
                {
                    fecha_recepcion !== null && 
                    id_estado_orden_trabajo === "1" &&
                    <small>Recolectado el <br/> {fecha_recepcion}</small>
                }
                {
                    fecha_entrega !== null && fecha_que_entrego === null &&
                    <>
                        <br />
                        <small>La próxima entrega es <br/> {fecha_entrega}</small>
                    </>
                }
                {
                    fecha_que_entrego !== null &&
                    <>
                        <br />
                        <small>Lo entregó el <br/> {fecha_que_entrego}</small>
                    </>
                }
            </td>

            <td className='table-null-no-wrap'>
                {
                    nombre_laboratorio !== null &&
                    <>
                        <p>{nombre_laboratorio}</p>
                        <small className="text-muted">Trabajo: {nombre_trabajo_laboratorio}</small>
                        <br />
                        <small className="text-muted font-weight-bold">Total: {parseFloat(costo_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small>
                        <br />
                        <small className="text-muted font-weight-bold">Pagado: {parseFloat(total_pagado).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small>
                    </>
                }
            </td>
            
            <td>
                <p>{nombre_estado_orden_trabajo}</p>
            </td>

        </tr>
    )

}